<template>
  <el-dialog
    class="process-season-card-order-dialog"
    :title="$t(`${baseLocoKey}.nmbs.title`)"
    :visible.sync="dialogFormVisible"
    @close="close"
  >
    <el-alert type="info" :closable="false" show-icon>
      <span v-html="$t(`${this.baseLocoKey}.nmbs.alert_message`)"></span>
    </el-alert>
    <div class="order-info">
      <order-field
        v-for="field in orderData"
        :field="field"
        :key="field.title"
      />
    </div>
    <el-form :key="dialogKey">
      <template>
        <base-section
          noPadding
          :title="$t(`${baseLocoKey}.employee_number`)"
          :withBorder="false"
        >
          <base-input
            required
            v-model="form.employeeNumber"
            :validations="$v.form.employeeNumber"
          />
        </base-section>
      </template>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <base-button
        :loading="loading"
        type="primary"
        :label="$t(`${baseLocoKey}.success_message`)"
        @click="handleConfirm"
      ></base-button>
    </span>
  </el-dialog>
</template>

<script>
import ProcessSeasonOrder from '@/mixins/ProcessSeasonOrder'
import OrderField from './OrderField'

export default {
  name: 'ProcessNmbsOrder',
  mixins: [ProcessSeasonOrder],
  components: { OrderField },
  data () {
    return {}
  },
  computed: {
    orderData () {
      return [
        {
          title: `${this.baseLocoKey}.gender`,
          content: this.order.userGender
        },
        {
          title: `${this.baseLocoKey}.lastname`,
          content: this.order.userLastName
        },
        {
          title: `${this.baseLocoKey}.firstname`,
          content: this.order.userFirstName
        },
        {
          title: `${this.baseLocoKey}.street`,
          content: this.order.userHomeStreet
        },
        {
          title: `${this.baseLocoKey}.house_number`,
          content: this.order.userHomeStreetNumber
        },
        {
          title: `${this.baseLocoKey}.postal_code`,
          content: this.order.userHomeZipCode
        },
        {
          title: `${this.baseLocoKey}.city`,
          content: this.order.userHomeCity
        },
        {
          title: `${this.baseLocoKey}.country`,
          content: this.order.userHomeCountry
        },
        {
          title: `${this.baseLocoKey}.language`,
          content: this.order.userLanguage
        },
        {
          title: `${this.baseLocoKey}.budget_type`,
          content: this.getTranslation(this.order.budgetTypeName) || ''
        },
        {
          title: `${this.baseLocoKey}.date_of_birth`,
          content: this.formatDate(this.order.userBirthDate)
        },
        {
          title: `${this.baseLocoKey}.nmbs.origin`,
          content: this.order.orderDetails.originDescription
        },
        {
          title: `${this.baseLocoKey}.nmbs.destination`,
          content: this.order.orderDetails.destinationDescription
        },
        {
          title: `${this.baseLocoKey}.nmbs.class`,
          content: this.order.orderDetails.classOfService
        },
        {
          title: `${this.baseLocoKey}.nmbs.subnets`,
          content: this.order.orderDetails.ticketOptionTypes
        },
        {
          title: `${this.baseLocoKey}.nmbs.include_parking`,
          content: this.order.orderDetails.includeParking + ''
        },
        {
          title: `${this.baseLocoKey}.nmbs.type`,
          content: this.order.orderDetails.product.productNameLabel.defaultValue
        },
        {
          title: `${this.baseLocoKey}.email`,
          content: this.order.userEmail
        },
        {
          title: `${this.baseLocoKey}.remark`,
          content: this.order.orderDetails?.personalReference || ''
        },
        {
          title: `${this.baseLocoKey}.b2b_contract_number`,
          content: this.order.b2bContractNumber
        }
      ]
    }
  }
}
</script>

<style lang="scss"></style>
