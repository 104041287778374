import { render, staticRenderFns } from "./ProcessMivbOrder.vue?vue&type=template&id=78f20363"
import script from "./ProcessMivbOrder.vue?vue&type=script&lang=js"
export * from "./ProcessMivbOrder.vue?vue&type=script&lang=js"
import style0 from "./ProcessMivbOrder.vue?vue&type=style&index=0&id=78f20363&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports