import { required } from 'vuelidate/lib/validators'
import { PROVIDERS, trans } from '@/app/helpers'

const initialOrderData = () => ({
  mobibCardNumber: null,
  mrpName: null,
  mrpOspId: null,
  orderUuid: null,
  orderDetails: {
    personalReference: '',
    product: {
      productNameLabel: { defaultValue: '' }
    }
  },
  price: { priceVatIncl: null },
  productName: null,
  requestedOn: null,
  serviceResourceOspId: null,
  startDate: null,
  userBirthDate: null,
  userEmail: null,
  userEmployeeNumber: null,
  userFirstName: null,
  userGender: null,
  userHomeCity: null,
  userHomeCountry: null,
  userHomeStreet: null,
  userHomeStreetNumber: null,
  userHomeZipCode: null,
  userLanguage: null,
  userLastName: null,
  userNationalNumber: null,
  userOspId: null,
  validityUnit: null,
  validityValue: 0
})

const initialFormData = () => ({
  orderUuid: null,
  price: null,
  startDate: null,
  remark: null,
  employeeNumber: null
})

export default {
  name: 'ProcessOrder',
  data () {
    return {
      order: initialOrderData(),
      loading: false,
      form: initialFormData(),
      dialogKey: '',
      dialogFormVisible: false
    }
  },
  computed: {
    baseLocoKey () {
      return 'order_handling.season_card_orders.process_order'
    },
    isNmbs () {
      return this.order.mrpOspId === PROVIDERS.NMBS
    },
    isDeLijn () {
      return this.order.mrpOspId === PROVIDERS.DELIJN
    },
    isMivb () {
      return this.order.mrpOspId === PROVIDERS.MIVB
    },
    orderData () {
      return [
        {
          title: `${this.baseLocoKey}.lastname`,
          content: this.order.userLastName
        },
        {
          title: `${this.baseLocoKey}.firstname`,
          content: this.order.userFirstName
        },
        {
          title: `${this.baseLocoKey}.street`,
          content: this.order.userHomeStreet
        },
        {
          title: `${this.baseLocoKey}.house_number`,
          content: this.order.userHomeStreetNumber
        },
        {
          title: `${this.baseLocoKey}.postal_code`,
          content: this.order.userHomeZipCode
        },
        {
          title: `${this.baseLocoKey}.city`,
          content: this.order.userHomeCity
        },
        {
          title: `${this.baseLocoKey}.country`,
          content: this.order.userHomeCountry
        },
        {
          title: `${this.baseLocoKey}.date_of_birth`,
          content: this.formatDate(this.order.userBirthDate)
        },
        {
          title: `${this.baseLocoKey}.language`,
          content: this.order.userLanguage
        },
        {
          title: `${this.baseLocoKey}.budget_type`,
          content: this.getTranslation(this.order.budgetTypeName) || ''
        },
        {
          title: `${this.baseLocoKey}.mobib_card_number`,
          content: this.order.mobibCardNumber
        },
        {
          title: `${this.baseLocoKey}.national_number`,
          content: this.order.userNationalNumber
        },
        {
          title: `${this.baseLocoKey}.ordered_product`,
          content: this.order.productName
        },
        {
          title: `${this.baseLocoKey}.duration`,
          content: `${this.order.validityValue} ${this.order.validityUnit}`.toLowerCase()
        },
        {
          title: `${this.baseLocoKey}.start_date`,
          content: this.order.startDate
        },
        {
          title: `${this.baseLocoKey}.season_ticket_price`,
          content: this.$currency(this.order.price.priceVatIncl)
        },
        {
          title: `${this.baseLocoKey}.email`,
          content: this.order.userEmail
        },
        {
          title: `${this.baseLocoKey}.remark`,
          content: this.order.orderDetails?.personalReference || ''
        }
      ]
    }
  },
  methods: {
    show (order) {
      this.order = order
      this.form.orderUuid = order.orderUuid
      if (this.isNmbs) {
        this.form.employeeNumber = order.userEmployeeNumber
      }
      if (!this.isNmbs && !this.isDeLijn) {
        this.form.startDate = order.startDate
        this.form.price = order.price && order.price.priceVatIncl
      }
      this.dialogKey = this.$key()
      this.dialogFormVisible = true
    },
    close () {
      this.dialogFormVisible = false
      this.order = initialOrderData()
      this.form = initialFormData()
    },
    handleConfirm () {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        const form = Object.fromEntries(
          Object.entries(this.form).filter(([key, value]) => value !== null)
        )
        this.$emit('confirm', { form, event: 'confirmOrder' })
        this.close()
      }
    },
    formatDate (date) {
      return date
        ? this.$date.fromISO(date).toFormat('dd/MM/yyyy')
        : date
    },
    getTranslation (data) {
      return trans(data)
    }
  },
  validations () {
    if (this.isNmbs) {
      return {
        form: {
          employeeNumber: {
            required
          }
        }
      }
    }
    return {
      form: {}
    }
  }
}
