<template>
  <el-dialog
    id="process-delijn-season-card-order"
    class="process-season-card-order-dialog"
    :title="$t(`${baseLocoKey}.title`)"
    :visible.sync="dialogFormVisible"
    @close="close"
  >
    <el-alert type="info" :closable="false" show-icon>
      <span v-html="$t(`${this.baseLocoKey}.delijn.alert_message`)"></span>
    </el-alert>
    <div class="order-info">
      <order-field v-for="field in orderData" :field="field" :key="field.title" />
    </div>
    <span slot="footer" class="dialog-footer">
      <base-button
        :loading="loading"
        type="primary"
        :label="$t(`${baseLocoKey}.success_message`)"
        @click="handleConfirm"
      ></base-button>
    </span>
  </el-dialog>
</template>

<script>
import ProcessSeasonOrder from '@/mixins/ProcessSeasonOrder'
import OrderField from './OrderField'

export default {
  name: 'ProcessDeLijnOrder',
  mixins: [ProcessSeasonOrder],
  components: { OrderField }
}
</script>

<style lang="scss">
#process-delijn-season-card-order {
  .el-dialog__footer {
    text-align: center;
  }
}
</style>
