<template>
  <base-section :title="$t(field.title)" noPadding :withBorder="false">
    <base-button
      v-if="field.content"
      @click.stop="handleCopy(field.content)"
      size="small"
      prepend-icon="ri-file-copy-line"
    ></base-button>
    <span class="text-content order-field-content">{{ field.content }}</span>
  </base-section>
</template>

<script>
export default {
  name: 'OrderField',
  props: {
    field: {
      type: Object,
      default: () => ({
        title: '',
        content: ''
      })
    }
  },
  methods: {
    handleCopy (message) {
      this.$copyText(message).then(e => {
        this.$notify({
          message: this.$t(
            'order_handling.season_card_orders.process_order.copy_success_message'
          ),
          type: 'success'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.order-field-content {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
</style>
