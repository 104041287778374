<template>
  <el-dialog
    :title="$t('order_handling.season_card_orders.cancel_order.title')"
    :visible.sync="dialogFormVisible"
    @close="close"
    id="cancel-season-card-order"
  >
    <el-alert type="info" :closable="false" show-icon>
      <span v-html="$t('order_handling.season_card_orders.cancel_order.alert_message')"></span>
    </el-alert>
    <el-form :key="dialogKey" :model="form" label-position="top">
      <base-input
        type="textarea"
        :autosize="{ minRows: 5, maxRows: 8 }"
        v-model.trim="form.message"
        prop="message"
        name="message"
        @submit="handleConfirm"
      />
    </el-form>
    <span slot="footer" class="dialog-footer">
      <base-button
        :loading="loading"
        type="primary"
        :label="$t('general.button_label.save_changes')"
        @click="handleConfirm"
      ></base-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'CancelOrder',
  data () {
    return {
      order: null,
      loading: false,
      form: {
        orderUuid: '',
        message: ''
      },
      dialogKey: '',
      dialogFormVisible: false
    }
  },
  methods: {
    show ({ orderUuid }) {
      this.form.orderUuid = orderUuid
      this.dialogKey = this.$key()
      this.dialogFormVisible = true
    },
    close () {
      this.dialogFormVisible = false
      this.form = {
        orderUuid: '',
        message: ''
      }
    },
    handleConfirm () {
      this.$emit('confirm', { form: this.form, event: 'cancelOrder' })
      this.close()
    }
  }
}
</script>

<style lang="scss">
#cancel-season-card-order {
  .el-form {
    margin-top: 32px;
  }
  .el-alert__description {
    span {
      word-break: initial;
    }
  }
}
</style>
